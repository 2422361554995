import mixin from '@c/mixins'
import simpleHeader from '@c/simpleHeader.vue'
import simpleFooter from '@c/simpleFooter.vue'
import { sendMsg, loginByPassword, loginByCode } from '@api'
export default {
    name: 'Login',
    mixins: [mixin],
    components: {
        simpleHeader,
        simpleFooter
    },
    data() {
        return {
            loginType: 1,
            ruleForm: {
                username: '',
                password: '',
                code: ''
            }
        }
    },
    methods: {
        switchLoginType(type) {
            this.loginType = type
            this.$refs.ruleForm.resetFields()
        },
        // 发送短信
        async sendMsg() {
            this.$refs.ruleForm.validateField('username', async error => {
                console.log(error)
                if (!error) {
                    const res = await sendMsg({
                        mobile: this.ruleForm.username,
                        type: 1
                    })
                    console.log('res', res)
                    this.$notify({ title: '提示', type: res.detail.status === 1 ? 'success' : 'error', message: res.msg || '短信验证码已发送到您的手机，请注意查收', position: 'bottom-right' })
                }
            })
        },
        async onSubmit() {
            this.$refs['ruleForm'].validate(async valid => {
                if (valid) {
                    const res = this.loginType === 1 ? await loginByPassword(this.ruleForm) : await loginByCode(this.ruleForm)
                    console.log('res', res)
                    const msg = [
                        '登录成功',
                        '用户名或密码不符合规范',
                        '账号或密码错误',
                        '该账号已被封禁不可登录'
                    ]
                    const status = +res.detail.status
                    this.$notify({ title: '提示', type: status === 1 ? 'success' : 'error', message: res.msg || msg[status - 1], position: 'bottom-right' })
                    // 登录成功跳转首页
                    if (res.detail.status === '1') {
                        // 缓存登录信息
                        localStorage.setItem('USER_INFO', this.ruleForm.username)
                        this.$router.push('/')
                    } else {
                        this.$refs['ruleForm'].resetFields()
                    }
                }
            })
        }
    }
}
